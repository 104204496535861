import * as React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';

import { PricingsRecordsColumns } from '../../types/ec2Instances';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles({
  selectRootChkBox: {
    '& .MuiInputBase-input ': {
      paddingRight: '24px !important',
    },
    height: '38px'
  },
  colorPrimary: {
    color: '#0B1626 !important'
  }
});

interface DropWithChkBoxProps {
  title?: string;
  list: PricingsRecordsColumns[] | null;
  value: string[];
  onColumnSelectHandler: (value: string) => void;
  renderValue?: (value: string[]) => React.ReactNode;
  displayEmpty?: boolean;
  multiple?: boolean;
}

const DropDownWithChkBox: React.FC<DropWithChkBoxProps> = (props) => {
  const { value, onColumnSelectHandler } = props;

  const classes = useStyles();

  const onColumnSelect = (field: string) => {
    onColumnSelectHandler(field);
  };

  return (
    <Box display='flex' justifyContent='end' mr={0.5}>
      <FormControl size='small'>
        <Select
          {...props}
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          MenuProps={MenuProps}
          IconComponent={FilterList}
          className={classes.selectRootChkBox}
        >
          {props.list?.map((listObj) => {
            const { field, headerName } = listObj;
            return (
              <Box key={field} onClick={() => onColumnSelect(field)}>
                <MenuItem value={field}>
                  <Checkbox
                    checked={value.includes(field)}
                    classes={{colorPrimary: classes.colorPrimary}}
                  />
                  <ListItemText
                    className={classes.colorPrimary}
                    primary={headerName} />
                </MenuItem>
              </Box>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownWithChkBox;