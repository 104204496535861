import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import {  makeStyles } from '@mui/styles'

import { PricingsRecordsColumns, PricingsRecordsObjType } from '../types/ec2Instances';


const useStyles = makeStyles({
  tableWrapper: {},
  dataGridClassName: {
    '& .MuiDataGrid-cell': {
      borderBottom: '2px solid #EEEEF2',
    },
  },
  dataGridRoot: {
    border: '2px solid #EEEEF2',
    borderRadius: 0,
    '& .MuiDataGrid-renderingZone': {
      // maxHeight: "36px !important"
    },
    '& .MuiDataGrid-cell': {
      lineHeight: "unset !important",
      // maxHeight: "36px !important",
      // minHeight: "36px !important",
      whiteSpace: "normal",
      borderBottom: '2px solid #EEEEF2 !important',
    },
    '& .MuiDataGrid-row': {
      // maxHeight: "36px !important",
      // minHeight: "36px !important",
    },
    '& .MuiDataGrid-root': {
      color: '#0B1626',
    },
  },
  dataGridColumnHeader: {
    borderRight: '2px solid #EEEEF2 !important',
    minHeight: '48px !important',
    maxHeight: '48px !important',
    height: '48px !important',
    lineHeight: '48px !important',
    alignItems: 'left !important',
  },
  dataGridColumnHeaders: {
    borderBottom: '2px solid #EEEEF2',
    borderRadius: 0,
    minHeight: '48px !important',
    maxHeight: '48px !important',
    height: '48px !important',
    lineHeight: '48px !important',
  },
  columnHeaderTitle: {
    fontWeight: 700,
    fontSize: '14px !important',
    lineHeight: '16px !important',
    color: '#2D2D2D !important'
  },
  dataGridRow: {
    color: '#2D2D2D !important',
    fontWeight: 400,
    fontSize: '14px !important',
    lineHeight: '16px !important',
  },
  dataGridCell: {
      borderRight: '2px solid #EEEEF2 !important',
      borderBottom: '2px solid #EEEEF2 !important',
      borderRadius: 0,
  },
  dataGridColumnSeparator: {
      display: 'none !important',
  },
  dataGridVirtualScroller: {
    marginTop: '48px !important',
  },
  dataGridColumnHeadersInner: {
    minHeight: '48px !important',
    maxHeight: '48px !important',
    height: '48px !important',
    lineHeight: '48px !important',
  },
});

interface Props {
  loading: boolean;
  data: PricingsRecordsObjType[] | [];
  columns: PricingsRecordsColumns[] | null;
}

const GridTable: React.FC<Props> = ({loading, data, columns}) => {
  const classes = useStyles()

  const tableHeight = (data.length > 0 && !loading)? `${((data.length)*52+77)}px` : `250px`;

  return (
    <Box style={{ height: tableHeight, width: '100%' }}>
      <DataGrid
        className={classes.tableWrapper}
        rows={loading? [] : data || []}
        columns={columns ? columns.map(col => ({ ...col })) : []}
        loading={loading}
        disableColumnMenu
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        classes={{
          root: classes.dataGridRoot,
          columnHeader: classes.dataGridColumnHeader,
          columnHeaders: classes.dataGridColumnHeaders,
          columnHeaderTitle: classes.columnHeaderTitle,
          row: classes.dataGridRow,
          cell: classes.dataGridCell,
          columnSeparator: classes.dataGridColumnSeparator,
          virtualScroller: classes.dataGridVirtualScroller,
          columnHeadersInner: classes.dataGridColumnHeadersInner,
        }}
      />
    </Box>
  )
};

export default GridTable;