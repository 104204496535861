import React from 'react';

interface EmptyButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const EmptyButton: React.FC<EmptyButtonProps> = ({
  onClick,
  children,
  className,
  disabled,
}) => {
  return (
    <button
      className={className}
      style={{
        appearance: 'none',
        border: 0,
        background: 'none',
        padding: '0',
      }}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default EmptyButton;

EmptyButton.defaultProps = {
  onClick: () => {},
  className: '',
};
