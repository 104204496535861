import UsageAPI from './Usage';
import { PricingsFilterObjType } from '../types/ec2Instances';
import { PaginationParams } from '../types/common';

const ec2InstancesAPI = {
  getRegionsInstanceTypes: async () => {
    const result = await UsageAPI.get('/public/v1/ec2-instance/cloud/aws/taxonomy');
    return result.data;
  },

  getPricingsRecords: async (filterObject: PricingsFilterObjType, paginationObject: PaginationParams) => {
    const { offset, limit } = paginationObject;
    const result = await UsageAPI.post(`/public/v1/ec2-instance/price?offset=${offset}&limit=${limit}`, filterObject);
    return result.data;
  }
}

export default ec2InstancesAPI;