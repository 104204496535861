export const ec2InstancesStaticFilterArrObj = {
  os: [
    { value: 'None', title: 'None' },
    { value: 'Linux', title: 'Linux' },
    { value: 'Windows', title: 'Windows' },
    { value: 'RHEL', title: 'RHEL' },
    { value: 'SLES', title: 'SLES' }
  ],
  tenancy: [
    { value: 'None', title: 'None' },
    { value: 'Shared', title: 'Shared' },
    { value: 'Dedicated', title: 'Dedicated' }
  ],
  upfrontOption: [
    { value: 'None', title: 'None' },
    { value: 'No Upfront', title: 'No Upfront' },
    { value: 'Partial Upfront', title: 'Partial Upfront' },
    { value: 'All Upfront', title: 'Full Upfront' }
  ],
  termLength: [
    { value: 'None', title: 'None' },
    { value: '1yr', title: '1year' },
    { value: '3yr', title: '3year' }
  ],
  offeringClass: [
    { value: 'None', title: 'None' },
    { value: 'standard', title: 'Standard' },
    { value: 'convertible', title: 'Convertible' }
  ],
  preinstalledSw: [
    { value: 'None', title: 'None' },
    { value: 'SQL Std', title: 'SQL Standard' },
    { value: 'SQL Ent', title: 'SQL Enterprise' },
    { value: 'SQL Web', title: 'SQL Web' },
    { value: 'NA', title: 'N/A' }
  ]
};

export const ec2InstancesStaticFilterTitle = {
  os: 'Os',
  tenancy: 'Tenancy',
  upfrontOption: 'Up Front Option',
  termLength: 'Term Length',
  offeringClass: 'Offering Class',
  preinstalledSw: 'Pre Installed Software'
};

export const perPageRow = [25, 50, 100];

export const mobileSize = 520;