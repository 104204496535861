import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {createGenerateClassName, StylesProvider} from '@mui/styles';
import '@fontsource/ibm-plex-sans';


const generateClassName = createGenerateClassName({
  seed: 'jssgenerate1',
});

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  }
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </Provider>
  </React.StrictMode>
);
