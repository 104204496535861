import * as React from 'react';
import { Typography, TypographyTypeMap } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface HeaderProps {
  heading: string
  className?: string
  component?: any
  variant?: TypographyTypeMap['props']['variant']
}

const useStyles = makeStyles({
});

const Header: React.FC<HeaderProps> = ({
  heading,
  className,
  component,
  variant = 'h4',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography className={className} component={component} variant={variant} {...rest}>{heading}</Typography>
  )
}

export default Header;