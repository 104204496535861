import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  buttonCss: {
    borderColor: '#c4c4c4',
    borderRadius: '4px',
    color: '#0B1626',
    fontSize: '16px',
    fontWeight: 700,
    height: '38px'
  },
  containedbtnCss: {
    backgroundColor: '#0B1626',
    borderColor: '#000000',
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 700,
    '&.MuiButtonBase-root:hover': {
      backgroundColor: '#0B1626'
    }
  }
});

interface CustomButtonProp {
  btnTitle: string;
  variantType: 'text' | 'outlined' | 'contained' | undefined;
  givenColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  onClickHandler: () => void;
}

const CustomButton: React.FC<CustomButtonProp> = ({
  btnTitle,
  variantType,
  givenColor,
  onClickHandler
}) => {
  const classes = useStyles();

  const givenClasses = variantType !== 'contained'?
    classes.buttonCss :
    classes.containedbtnCss;

  return <Button
    variant={variantType}
    color={givenColor}
    onClick={onClickHandler}
    classes={{
      root: givenClasses
    }}
  >
    {btnTitle}
  </Button>
};

export default CustomButton;