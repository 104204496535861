import * as React from 'react';
import { Box,
  MenuItem,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { filterListObj } from '../../types/common';

interface DropDownProps {
  title?: string,
  list: string[] | number[] | filterListObj[],
  onFilterChange: (value: string | number) => void,
  selectedValue?: string | number
}

const useStyles = makeStyles(theme => ({
  select: {
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontcolor: '#0B1626 !important',
    '& .MuiInputLabel-root': {
      fontWeight: 700,
      color: '#0B1626'
    },
    '&:focus': {
      color: '#0B1626',
    }
  },
  nativeInput: {
    fontWeight: '700 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& .MuiInputLabel-root': {
      fontWeight: '700 !important',
      fontSize: '16px !important',
      color: '#0B1626 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
      borderWidth: '1px !important ',
    },
    '& .MuiOutlinedInput-root': {
      fontWeight: '700 !important',
      fontSize: '16px !important',
      color: '#0B1626 !important',
      height: '38px',
      '& .Mui-focused': {
        '&. MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderWidth: 1,
        },
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
  },
  menu: {
    width: '100%',
  },
  menuRoot: {
    fontSize: '16px !important',
    color: '#0B1626 !important',
  }
}));

const DropDownSelect: React.FC<DropDownProps> = ({
  title,
  list,
  onFilterChange,
  selectedValue
}) => {

  const classes = useStyles();

  const handleChange = (event: any) => {
    const { value } = event.target;
    onFilterChange(value);
  };

  return (
    <Box>
      <form noValidate autoComplete="off">
        <TextField
          size="small"
          select
          label={(!selectedValue || (selectedValue === 'None')) ? title: ''}
          className={classes.select}
          classes={{root: classes.textField}}
          value={selectedValue !== 'None'? selectedValue?.toString() : ''}
          onChange={handleChange}
          InputLabelProps={{shrink: false}}
          margin="normal"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {list?.map((valueObj) => {
            if (typeof valueObj === 'object') {
              return <MenuItem
                classes={{root: classes.menuRoot}}
                key={valueObj.value}
                value={valueObj.value}
              >
                {valueObj.title}
              </MenuItem>
            }
            else {
              return <MenuItem
                classes={{root: classes.menuRoot}}
                key={valueObj}
                value={valueObj}
              >
                {valueObj}
              </MenuItem>
            }
          })}
        </TextField>
      </form>
    </Box>
  );
};

export default DropDownSelect;
