import React from 'react';

import InstancesPrice from './components/Page/InstancesPrice';
import FeedBackIssueTracker from "./components/Common/FeedBackIssueTracker";

const App: React.FC = () =>
  <>
    <InstancesPrice />
    <FeedBackIssueTracker/>
  </>

export default App;
