import { combineReducers } from '@reduxjs/toolkit';

import { reducer as exampleReducer } from '../slices/example';
import { reducer as ec2InstancesReducer } from '../slices/ec2Instances';

const rootReducer = combineReducers({
  example: exampleReducer,
  ec2Instances: ec2InstancesReducer
});

export default rootReducer;
