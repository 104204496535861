import { useState } from 'react';

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

interface NotificationState {
  open: boolean;
  notificationType: NotificationType;
  message: string;
}

const useToggleNotification = () => {
  const [notificationState, setNotificationState] = useState<NotificationState>({
    open: false,
    notificationType: 'success',
    message: '',
  });

  const handleNotificationClose = () => {
    setNotificationState((prev) => ({ ...prev, open: false }));
  };

  const handleNotification = (msg: string, type: NotificationType) => {
    setNotificationState({
      open: true,
      notificationType: type,
      message: msg,
    });
  };

  return {
    open: notificationState.open,
    notificationType: notificationState.notificationType,
    message: notificationState.message,
    handleNotification,
    handleNotificationClose,
  };
};

export default useToggleNotification;
