import React, { useMemo, useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  Link,
  DialogContent,
  DialogActions
} from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid';
import { InfoOutlined } from '@mui/icons-material';
import { styled, makeStyles } from '@mui/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Header from '../../Common/Header';
import GridTable from '../../GridTable';
import DropDownSelect from '../../Common/DropDownSelect';
import AutoCompleteComponent from '../../Common/AutoComplete';
import useToggleNotification from '../../../hooks/useToggleNotification';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PaginationComponent from '../../Common/Pagination';
import DropDownWithCheckBox from '../../Common/DropDownWithChkBox';
import AlertHeader from '../../Common/AlertHeader';

import {
  ec2InstancesStaticFilterArrObj,
  ec2InstancesStaticFilterTitle, mobileSize,
  perPageRow
} from '../../../const/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  fetchPricingsRecords,
  fetchRegionAndInstanceTypes,
  setRegionAndInstanceTypesLoading,
  setPricingsRecordLoading
} from '../../../slices/ec2Instances';
import { PricingsRecordsColumns } from '../../../types/ec2Instances';
import CustomButton from '../../Common/CustomButton';
import {BootstrapDialog, BootstrapDialogTitle} from '../../Common/BootstrapDialog';
import { nanoid } from '@reduxjs/toolkit';

const initialFilterObj = {
  instanceTypes: [],
  regions: [],
  os: 'Linux',
  upfrontOption: 'No Upfront',
  tenancy: '',
  termLength: '',
  offeringClass: '',
  preinstalledSw: 'NA',
  upfrontCost: ''
};

const useStyles = makeStyles((theme: any) => ({
  mainContainer: {
    position: 'relative',
    background: 'linear-gradient(180deg, rgb(244 205 94 / 16%) 0%, rgb(231 113 223 / 18%) 52.6%, rgb(107 202 211 / 22%) 100%)',
    minHeight: '100vh',
  },
  filterBox: {
    justifyContent: 'between',
    display: 'flex',
    alignItems: 'center'
  },
  mainBox: {
    padding: '50px 75px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px 50px'
    }
  },
  mainContent: {
    position: 'relative',
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '4px',
    zIndex: 10,
    boxShadow: 'rgb(0 0 0 / 25%) 0px 4px 4px',
  },
  rootFilter: {},
  headerText: {
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '41.22px',
    fontcolor: '#000000',
  },
  alertContent: {
    margin: '15px 0',
    padding: '15px'
  },
  autoCompleteComp: {
    minWidth: '170px',
  },
  autoCompleteCompRegion: {
    width: '100px',
    minWidth: '100px',
  },
  termLength: {
    minWidth: '140px'
  },
  preinstalledSw: {
    minWidth: '210px'
  },
  offeringClass: {
    minWidth: '155px'
  },
  os: {
    minWidth: '75px'
  },
  upfrontOption: {
    minWidth: '160px'
  },
  tenancy: {
    minWidth: '115px'
  },
  wrapFilter: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterContainer: {
    paddingLeft: '4px',
    paddingTop: '4px'
  },
  moreFilterBtn: {
  },
  fieldFilter: {
    width: 'calc(100% - 130px)',
    display: 'flex',
    flexWrap: 'wrap',
  },
  columnResetFilterWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '130px',
    maxHeight: 40,
    [theme.breakpoints.down(mobileSize)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginTop: '10px'
    }
  },
  columnResetFilter: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '4px',
    paddingLeft: '4px'
  },
  columnHeaderWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  columnHeaderText: {
    paddingRight: '8px',
    paddingTop: '3px'
  },
  tooltip: {
    paddingTop: '9px',
    color: 'rgba(0, 0, 0, 0.54)',
  }
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0B1626',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0B1626',
  },
}));

const InstancesPrice: React.FC = () => {
  const [filterObj, setFilterObj] = useState(initialFilterObj);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPage: 0,
    offset: 0,
    limit: perPageRow[0]
  });
  const [ isMobileView, setIsMobileView ] = useState<boolean>(false);
  const [ isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const windowDimensions = useWindowDimensions();

  const classes = useStyles();

  const columns = [
    {
      field: 'instanceType',
      headerName: 'Instance Type',
      sortable: true,
      editable: false,
      hide: false,
      width: 170,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Instance Type'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Instance Type'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'region',
      headerName: 'Region',
      sortable: true,
      editable: false,
      hide: false,
      width: 140,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Region'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Region'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'os',
      headerName: 'OS',
      sortable: true,
      editable: false,
      hide: false,
      width: 100,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'OS'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='OS'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'tenancy',
      headerName: 'Tenancy',
      sortable: true,
      editable: false,
      width: 140,
      hide: false,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Tenancy'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Defines how EC2 instances are distributed across physical hardware.'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'upfrontOption',
      headerName: 'Upfront Option',
      sortable: true,
      editable: false,
      hide: false,
      width: 175,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Upfront Option'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Type of upfront fee needed to be paid for the reservation'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'termLength',
      headerName: 'Term Length',
      sortable: true,
      editable: false,
      hide: false,
      width: 160,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Term Length'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Term Length'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'offeringClass',
      headerName: 'Offering Class',
      sortable: true,
      editable: false,
      hide: false,
      width: 170,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => {
        const tooltipText = 'Standard:Customers have the flexibility to change the Availability Zone, ' +
          'the instance size, and networking type of their Standard Reserved Instances.\n ' +
          'Convertible: Additional flexibility, such as the ability to use different instance families, ' +
          'operating systems, or tenancies';
        return (<strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Offering Class'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )}
    },
    {
      field: 'preinstalledSw',
      headerName: 'Pre Installed Software',
      sortable: true,
      editable: false,
      hide: false,
      width: 220,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Pre Installed Software'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Pre Installed Software'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      )
    },
    {
      field: 'riRate',
      headerName: 'RI Rate',
      type: 'number',
      sortable: true,
      editable: false,
      width: 180,
      hide: false,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'RI Rate(Hourly)'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Reserved Instance Rate (Hourly)'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      ),
      renderCell:(params:{value:any})=>`$${params.value}`
    },
    {
      field: 'odRate',
      headerName: 'OD Rate',
      type: 'number',
      sortable: true,
      editable: false,
      width: 190,
      hide: false,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'OD Rate(Hourly)'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='On Demand Rate (Hourly)'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      ),
      renderCell:(params:{value:any})=>`$${params.value}`
    },
    {
      field: 'spRate',
      headerName: 'SP Rate',
      type: 'number',
      sortable: true,
      editable: false,
      width: 180,
      hide: false,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'SP Rate(Hourly)'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Savings Plan Rate (Hourly)'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      ),
      renderCell:(params:{value:any})=>`$${params.value}`
    },
    {
      field: 'upfrontCost',
      headerName: 'Upfront Cost',
      type: 'number',
      sortable: true,
      editable: false,
      hide: false,
      width: 160,
      headerAlign: 'left',
      align: 'left',
      renderHeader:  (params: GridColumnHeaderParams) => (
        <strong className={classes.columnHeaderWrap}>
          <span className={classes.columnHeaderText}>{'Upfront Cost'}</span>
          <span className={classes.tooltip}>
            <CustomTooltip
              title='Upfront Cost'
              placement='top'
            >
              <InfoOutlined color='action' fontSize='small'/>
            </CustomTooltip>
          </span>
        </strong>
      ),
      renderCell:(params:{value:any})=>`${(params.value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}`
    }
  ];

  const [gridColumn, setGridColumn] = useState<PricingsRecordsColumns[] | []>(columns);
  const dispatch = useAppDispatch();

  const { regionsInstancesloading, pricingRecords, pricingRecordsCount, regionsInstances, regionInstanceError, 
    pricingsLoading, pricingError
  } =
    useAppSelector((store) => store.ec2Instances);
  const getInstances =
    useAppSelector((store) => store?.ec2Instances?.regionsInstances?.aws?.instanceTypes, shallowEqual);
  const { handleNotification } =
    useToggleNotification();

  const handleDialogToggle = () => {
    setIsOpenDialog(!isOpenDialog)
  };

  const onMoreFilterHandler = () => {
    setIsOpenDialog(true);
  };

  const onResetHandler = () => {
    if (JSON.stringify(filterObj) !== JSON.stringify({ ...initialFilterObj })) {
      setFilterObj({ ...initialFilterObj });
      if (paginationData.currentPage !== 1 || paginationData.offset !== 0)
        setPaginationData({ ...paginationData, currentPage: 1, offset: 0});
    }
  };

  const mapPricingsRecords = useMemo(() => {
    return pricingRecords?.pricings?.map((pricingObj) => {
      return {...pricingObj, id: nanoid(), offeringClass:
          pricingObj.offeringClass === 'standard'?
            'Standard' :
            pricingObj.offeringClass};
    });
  },[pricingRecords]);

  const onRowChangeHandler = (selectedRowsPerPage: number) => {
    const localePaginationData = {...paginationData};
    if (localePaginationData.limit !== selectedRowsPerPage) {
      setPaginationData({...localePaginationData,
        limit: selectedRowsPerPage,
        currentPage: 1,
        offset: 0,
        totalPage: pricingRecordsCount && Math.ceil(pricingRecordsCount/selectedRowsPerPage) || 0
      })
    }
  };

  const onPageChangeHandler = (selectedPage: number) => {
    const localPaginationData = {...paginationData};
    if (localPaginationData.currentPage !== selectedPage) {
      const countedOffset = (selectedPage*localPaginationData.limit) - localPaginationData.limit;
      setPaginationData({...localPaginationData, currentPage: selectedPage, offset: countedOffset});
    }
  };

  const onFilterChangeHandler = (filterName: string, value: string) => {
    value = value === 'None'? '' : value;
    if ((filterObj as any)[`${filterName}`] !== value) {
      setFilterObj({...filterObj, [`${filterName}`]: value});
      // if (filterName === 'upfrontOption') {
      //   onColumnSelectHandler('upfrontCost', value === 'No Upfront'? true : false);
      // }
      if (paginationData.currentPage !== 1 || paginationData.offset !== 0)
        setPaginationData({ ...paginationData, currentPage: 1, offset: 0});
    }
  };

  const onColumnSelectHandler = (selectedField: string, value?: boolean) => {
    const localGridColumn = [...gridColumn];
    const selectedColumnIndx = localGridColumn.findIndex((columnObj) => columnObj.field === selectedField);
    localGridColumn[selectedColumnIndx] = {...localGridColumn[selectedColumnIndx],
      hide: value || !localGridColumn[selectedColumnIndx].hide};
    setGridColumn([...localGridColumn])
  };

  const getSelectedColumn = useMemo(() => {
    return (gridColumn.filter((colObj) => !colObj.hide)).map((column) => column.field);
  },[gridColumn]);

  const onSelectChangeHandler = (filterName: string, value: string) => {
    if ((filterObj as any)[`${filterName}`] !== value) {
      setFilterObj({...filterObj, [`${filterName}`]: value});
      if (paginationData.currentPage !== 1 || paginationData.offset !== 0)
        setPaginationData({ ...paginationData, currentPage: 1, offset: 0});
    }
  };

  const getRegionsAndInstanceTypes = () => {
    dispatch(setRegionAndInstanceTypesLoading(true));
    try {
      dispatch(fetchRegionAndInstanceTypes());
    } catch (e) {
      dispatch(setRegionAndInstanceTypesLoading(false));
    }
  };

  const getAllPricingsRecords = () => {
    dispatch(setPricingsRecordLoading(true));
    try {
      const { offset, limit } = paginationData;
      dispatch(fetchPricingsRecords({...filterObj}, { offset, limit }));
    } catch (e) {
      dispatch(setPricingsRecordLoading(false));
    }
  };

  const regionFilter =
    <Grid item className={`${classes.autoCompleteComp} ${classes.filterContainer}`}

    >
      <AutoCompleteComponent
        label={'Region'}
        list={regionsInstances?.aws?.regions || []}
        value={filterObj.regions}
        isMultiple={true}
        onSelectChangeHandler={(value) => onSelectChangeHandler('regions', value)}
      />
    </Grid>;

  const dropDownFilter = Object.keys(ec2InstancesStaticFilterArrObj)
    .map((filterName) => {
        let defaultClass = classes.autoCompleteComp;
        if (filterName === 'os') defaultClass = classes.os;
        if (filterName === 'upfrontOption') defaultClass = classes.upfrontOption;
        if (filterName === 'tenancy') defaultClass = classes.tenancy;
        if (filterName === 'termLength') defaultClass = classes.termLength;
        if (filterName === 'preinstalledSw') defaultClass = classes.preinstalledSw;
        if (filterName === 'offeringClass') defaultClass = classes.offeringClass;
        const selectedValue = (filterObj as any)[filterName];
        return <Grid item className={`${defaultClass} ${classes.filterContainer}`} key={filterName}>
          <DropDownSelect
            key={filterName}
            title={(ec2InstancesStaticFilterTitle as any)[filterName]}
            list={(ec2InstancesStaticFilterArrObj as any)[filterName] || []}
            onFilterChange={(value) => onFilterChangeHandler(filterName, (value).toString())}
            selectedValue={ selectedValue}
          />
        </Grid>
      });

  const MoreFilters = <Box className={classes.wrapFilter} style={isMobileView?
    {flexDirection: 'column'} :
    {flexDirection: 'row'}}
  >
    {regionFilter}
    {dropDownFilter}
  </Box>;

  useEffect(() => {
    const { width, height } = windowDimensions;
    if (width < mobileSize) !isMobileView && setIsMobileView(true);
    else {
      isMobileView && setIsMobileView(false);
      isOpenDialog && setIsOpenDialog(false);
    }
  },[windowDimensions]);

  useEffect(() => {
    getRegionsAndInstanceTypes();
  }, []);

  useEffect(() => {
    if (getInstances && getInstances.length > 0) {
      getAllPricingsRecords();
    }
  }, [getInstances, filterObj, paginationData.currentPage, paginationData.limit]);

  useEffect(() => {
    if (pricingRecordsCount) {
      setPaginationData({...paginationData,
        totalPage: Math.ceil(pricingRecordsCount/paginationData.limit)
      });
    }
  },[pricingRecordsCount]);

  useEffect(() => {
    const errorMsg = regionInstanceError || pricingError;
    if (errorMsg)
      handleNotification(errorMsg, 'error');
  },[regionInstanceError, pricingError]);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.mainBox}>
        <Box className={classes.mainContent}>
          <Box mb={3}>
            <Header className={classes.headerText} heading='EC2 Pricing' />
            {/* <AlertHeader className={classes.alertContent}>
              <Typography variant="h6" >
                Reduce your AWS bill by <span>57%</span> with <Link href="https://usage.ai/" rel="noopener noreferrer" target="_blank">Usage</Link>.
              </Typography>
            </AlertHeader> */}
          </Box>
          <Box className={classes.wrapFilter}>
            <Box className={classes.fieldFilter}>
                <Box className={!isMobileView? classes.wrapFilter : ''}>
                  <Grid item className={`${classes.autoCompleteComp} ${classes.filterContainer}`}>
                    <AutoCompleteComponent
                      label={'Instance Type'}
                      list={getInstances || []}
                      value={filterObj.instanceTypes}
                      isMultiple={true}
                      onSelectChangeHandler={(value) => onSelectChangeHandler('instanceTypes', value)}
                    />
                  </Grid>
                  {!isMobileView && regionFilter}
                  {!isMobileView && dropDownFilter}
                </Box>
                {isMobileView &&
                  <Box className={classes.moreFilterBtn}>
                    <Grid item className={classes.filterContainer}>
                      <CustomButton
                        btnTitle='More Filter'
                        variantType='outlined'
                        givenColor='inherit'
                        onClickHandler={onMoreFilterHandler}
                      />
                    </Grid>
                  </Box>
                }
            </Box>
            <Box className={classes.columnResetFilterWrap}>
              <Box className={classes.columnResetFilter}>
                <DropDownWithCheckBox
                  value={getSelectedColumn || []}
                  list={columns || []}
                  onColumnSelectHandler={onColumnSelectHandler}
                  displayEmpty={true}
                  multiple={true}
                />
                <CustomButton
                  btnTitle='Reset'
                  variantType='outlined'
                  givenColor='inherit'
                  onClickHandler={onResetHandler}
                />
              </Box>
            </Box>
          </Box>

          <Box mt={3.5}>
            <GridTable
              loading={pricingsLoading || regionsInstancesloading}
              data={mapPricingsRecords || []}
              columns={gridColumn}
            />
          </Box>
          {mapPricingsRecords && mapPricingsRecords?.length > 0 &&
            <Box mt={3.5}>
              <PaginationComponent
                selectedRow={paginationData.limit}
                currentPage={paginationData?.currentPage}
                totalPage={paginationData?.totalPage}
                onPageChangeHandler={onPageChangeHandler}
                rowsPerPage={paginationData?.limit}
                onRowChangeHandler={onRowChangeHandler}
              />
          </Box>
          }
        </Box>
      </Box>
      {isMobileView &&
        <BootstrapDialog
          onClose={handleDialogToggle}
          aria-labelledby="filter-dialog"
          open={isOpenDialog}
        >
          <BootstrapDialogTitle
            onClose={handleDialogToggle}
          >
            More Filter
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {MoreFilters}
          </DialogContent>
          <DialogActions>
            <CustomButton
              btnTitle='Close'
              variantType='outlined'
              givenColor='inherit'
              onClickHandler={handleDialogToggle}
            />
          </DialogActions>
        </BootstrapDialog>
      }
    </Box>
  )
};

export default React.memo(InstancesPrice);


