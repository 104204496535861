import * as React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface AutoCompleteProps {
  label: string;
  list: string[];
  onSelectChangeHandler: (value: string) => void;
  isMultiple: boolean;
  value: string[];
}

const useStyles = makeStyles((theme: any) => ({
  autocompleteRoot: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  autoCompleteInputRoot: {
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 8px) scale(1)',
      fontWeight: 700,
      color: '#0B1626 !important'
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      fontWeight: 700,
      color: '#0B1626',
      hover: false,
    },
    '& ::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: '#0B1626',
      opacity: 1, /* Firefox */
    },
    '& :-ms-input-placeholder': { /* Internet Explorer 10-11 */
      color: '#0B1626',
    },
    '& ::-ms-input-placeholder': { /* Microsoft Edge */
      color: '#0B1626',
    },
  },
  inputColor: {
    color: '#0B1626 !important',
    fontWeight: '700 !important',
  },
  focused: {
    focusable: false,
    hover: false
  }

}));

const AutoCompleteComponent: React.FC<AutoCompleteProps> = ({label,
  list,
  onSelectChangeHandler,
  isMultiple,
  value
}) => {
  const classes = useStyles();

  const onHandleChange = (event: React.SyntheticEvent, value: any, reason: string ) => {
    onSelectChangeHandler(value);
  };

  return (
    <Autocomplete
      disablePortal
      id='combo-box-demo'
      limitTags={2}
      options={list}
      onChange={onHandleChange}
      fullWidth
      multiple={isMultiple}
      value={value}
      getOptionDisabled={(options) => (value.length > 3 ? true : false)}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{
            root: classes.autoCompleteInputRoot,
          }}
          placeholder={label}
          style={{ maxHeight: '36px !important' }}
          InputLabelProps={{
            className: classes.inputColor
          }}
        />
      )}
      classes={{
        root: classes.autocompleteRoot,
        focused: classes.focused
      }}
    />
  );
};

export default AutoCompleteComponent;
