import * as React from 'react';
import { InputLabel, Pagination, Box } from '@mui/material';
import {makeStyles} from '@mui/styles';

import { perPageRow } from '../../const/config';
import DropDownSelect from './DropDownSelect';

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#0B1626 !important',
      color: '#FFFFFF !important'
    },
    '& .MuiPaginationItem-root': {
      backgroundColor: '#F5F5F5'
    },
    '& .MuiPagination-ul li': {
      marginBottom: '5px'
    }
  },
  inputLabel: {
    paddingTop: '5px',
    color: '#2D2D2D',
    fontS: '14px',
    fontWeight: 700,
  },
  wrapPaginationContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  wrapRowSelectContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '190px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
      marginTop: '10px'
    }
  },
  rowSelectContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputContent: {
    marginRight: '10px'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% - 190px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
}));

interface PaginationProps {
  currentPage: number;
  totalPage: number;
  onPageChangeHandler: (page: number) => void;
  rowsPerPage: number;
  onRowChangeHandler: (value: number) => void;
  selectedRow: number;
};

const PaginationComponent: React.FC<PaginationProps> = ({
  currentPage,
  totalPage,
  onPageChangeHandler,
  rowsPerPage,
  onRowChangeHandler,
  selectedRow
}) => {

  const classes = useStyles();

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageChangeHandler(page);
  };

  const onRowChange = (value: number | string) => {
    onRowChangeHandler(Number(value));
  };

  return (
    <Box className={classes.wrapPaginationContent}>
      <Box className={classes.pagination}>
        <Pagination
          classes={{
            root: classes.root
          }}
          count={totalPage}
          shape='rounded'
          page={currentPage}
          onChange={onPageChange}
        />
      </Box>
      <Box className={classes.wrapRowSelectContent}>
        <Box className={classes.rowSelectContent}>
          <Box className={classes.inputContent}>
            <InputLabel
              className={classes.inputLabel}
              variant='standard'
              htmlFor='uncontrolled-native'>
              Rows per page:
            </InputLabel>
          </Box>
          <Box>
            <DropDownSelect
              title={'Size'}
              list={perPageRow || []}
              onFilterChange={onRowChange}
              selectedValue={selectedRow}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaginationComponent;