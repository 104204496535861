import React from 'react';
import {
  TextField,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';

import useToggleNotification from '../../hooks/useToggleNotification';
import UsageAPI from '../../api/Usage';
import CustomButton from './CustomButton';
import { BootstrapDialog, BootstrapDialogTitle } from './BootstrapDialog';
import EmptyButton from './EmptyButton';

const useStyles = makeStyles({
  buttonCss: {
    color: '#0B1626',
    width: '35px',
    height: '35px',
    position: 'fixed',
    right: '15px',
    bottom: '10px',
    fontSize: '1.25rem',
    cursor: 'pointer',
    zIndex: '10'
  },
});

const initialState = { title: '', feedback: '' };

const FeedBackIssueTracker: React.FC = () => {
  const { handleNotification } =
    useToggleNotification();
  const [show, setShow] = React.useState(false);
  const [err, setErr] = React.useState(initialState);
  const [formInput, setFormInput] = React.useState(initialState);
  const handleClose = () => {
    if (err.title || err.feedback) setErr(initialState);
    if (formInput.title || formInput.feedback) setFormInput(initialState);
    setShow(false);
  };

  const classes = useStyles();

  const handleOpenForm = () => setShow(true);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setFormInput({ ...formInput, [name]: newValue });
    validateForm({ [name]: newValue })
  };

  const validateForm = (validData: any) => {
    let isError = false
    const localErr = { ...err };
    if (!validData.title && validData.title === '') {
      localErr.title = 'Title is required*';
      isError = true;
    } else if (validData.title && localErr.title) {
      localErr.title = '';
      isError = false;
    }
    if(!validData.feedback && validData.feedback === '') {
      localErr.feedback = 'Feedback is required*';
      isError = true;
    } else if (validData.feedback && localErr.feedback) {
      localErr.feedback = '';
      isError = false;
    }
    setErr({ ...localErr });
    return isError;
  }

  const handleFormSubmit = () => {
    const { title, feedback } = formInput;
    if (validateForm(formInput)) {
      return;
    }

    const payload = {
      title: title,
      feedback: feedback,
      screenshot: '',
    };

    UsageAPI
      .post('/public/v1/feedback', payload)
      .then(() => {
        handleNotification('Thanks you for your feedback! We will get back to you', 'success');
        handleClose();
      })
      .catch((err) => {
        handleNotification(
          err.response.data.message ||
          'Failed, Internal server error, Please try later. ',
          'error'
        )
        handleClose();
      });
  };

  return (
    <>
      <div>
        <EmptyButton className={classes.buttonCss} onClick={handleOpenForm}>
          <FeedbackIcon
            fontSize='large'
            sx={{ fontSize: 45, transform: 'scale(-1, 1)' }}
          />
        </EmptyButton>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="feedback-dialog"
          open={show}
        >
          <BootstrapDialogTitle
            onClose={handleClose}
          >
            Feedback report
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TextField
              autoFocus
              margin='dense'
              id='title'
              name='title'
              label='Title*'
              type='text'
              fullWidth
              variant='outlined'
              error={Boolean(err.title)}
              helperText={err.title}
              onChange={handleInput}
              value={formInput.title || ''}
            />
            <TextField
              autoFocus
              margin='dense'
              id='feedback'
              name='feedback'
              label='Describe your Feedback*'
              type="text"
              fullWidth
              variant='outlined'
              multiline={true}
              rows={4}
              error={Boolean(err.feedback)}
              helperText={err.feedback}
              onChange={handleInput}
              value={formInput.feedback || ''}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              btnTitle='Close'
              variantType='outlined'
              givenColor='inherit'
              onClickHandler={handleClose}
            />
            <CustomButton
              btnTitle='Send'
              variantType='contained'
              givenColor='inherit'
              onClickHandler={handleFormSubmit}
            />
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default FeedBackIssueTracker;
