import { createSlice } from '@reduxjs/toolkit';

import exampleAPI from '../api/example';
import { Example, MutliExamplePayload } from '../types/example';

interface ExampleState {
  examples: Example[] | null;
  loading: boolean;
}

const initialState: ExampleState = {
  examples: null,
  loading: false,
};

const slice = createSlice({
  name: 'examples',
  initialState,
  reducers: {
    setExample(oldState: ExampleState, action: MutliExamplePayload) {
      oldState.examples = action.payload;
    },
    setLoading(oldState: ExampleState, action: { payload: boolean }) {
      oldState.loading = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setExampleLoading = (state: boolean) => async (dispatch: any) => {
  dispatch(slice.actions.setLoading(state));
};

export const fetchExample = () => async (dispatch: any) => {
  const result = await exampleAPI.getExamples();
  dispatch(slice.actions.setExample(result.response.events));
};

export default slice;
