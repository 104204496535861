import { createSlice } from '@reduxjs/toolkit';

import ec2InstancesAPI from '../api/ec2Instances';
import {
  Ec2Instances,
  PricingsFilterObjType,
  RegionInstanceTypesPayload,
  PricingsRecordsType, PricingsRecordsPayload, RegionInstanceErrorPayload, PricingsRecordsErrorPayload,
} from '../types/ec2Instances';
import { PaginationParams } from '../types/common';


interface RegionAndInstanceTypes {
  regionsInstances: Ec2Instances | null;
  regionsInstancesloading: boolean;
  regionInstanceError: string;
  pricingRecords: PricingsRecordsType | null;
  pricingRecordsCount: number | null;
  pricingsLoading: boolean;
  pricingError: string;
}

const initialState: RegionAndInstanceTypes = {
  regionsInstances: null,
  regionsInstancesloading: false,
  regionInstanceError: '',
  pricingRecords: null,
  pricingRecordsCount: null,
  pricingsLoading: false,
  pricingError: ''
};

const slice = createSlice({
  name: 'regionAndInstanceSlice',
  initialState,
  reducers: {
    setRegionAndInstanceTypes(oldState: RegionAndInstanceTypes, action: RegionInstanceTypesPayload) {
      oldState.regionsInstances = action.payload;
      oldState.regionInstanceError = '';
    },
    setRegionInstanceError(oldState: RegionAndInstanceTypes, action: RegionInstanceErrorPayload) {
      oldState.regionInstanceError = action.payload;
    },
    setLoading(oldState: RegionAndInstanceTypes, action: { payload: boolean }) {
      oldState.regionsInstancesloading = action.payload;
    },
    setPricingsRecords(oldState: RegionAndInstanceTypes, action: PricingsRecordsPayload) {
      oldState.pricingRecords = action.payload.response;
      oldState.pricingRecordsCount = action.payload.totalCount;
      oldState.pricingError = '';
    },
    setPricingsLoading(oldState: RegionAndInstanceTypes, action: { payload: boolean }) {
      oldState.pricingsLoading = action.payload;
    },
    setPricingsRecordsError(oldState: RegionAndInstanceTypes, action: PricingsRecordsErrorPayload) {
      oldState.pricingError = action.payload;
      oldState.pricingRecords = null;
      oldState.pricingRecordsCount = null;
    }
  },
});

export const { reducer } = slice;

export const setRegionAndInstanceTypesLoading = (state: boolean) => async (dispatch: any) => {
  dispatch(slice.actions.setLoading(state));
};

export const setPricingsRecordLoading = (state: boolean) => async (dispatch: any) => {
  dispatch(slice.actions.setPricingsLoading(state));
};

export const fetchRegionAndInstanceTypes = () => async (dispatch: any) => {
  try {
    const result = await ec2InstancesAPI.getRegionsInstanceTypes();
    dispatch(slice.actions.setRegionAndInstanceTypes(result.response));
    dispatch(slice.actions.setLoading(false));
  }
  catch (e) {
    dispatch(slice.actions.setRegionInstanceError('Error while fetching Instances and Regions'));
    dispatch(slice.actions.setLoading(false));
  }
};

export const fetchPricingsRecords =
  ( pricingFilterObj: PricingsFilterObjType,
    paginationObject: PaginationParams ) => async (dispatch: any) => {
    try {
      const result = await ec2InstancesAPI.getPricingsRecords(pricingFilterObj, paginationObject);
      dispatch(slice.actions.setPricingsRecords(result));
      dispatch(slice.actions.setPricingsLoading(false));
    }
    catch (e) {
      dispatch(slice.actions.setPricingsRecordsError('Error while fetching Pricing'));
      dispatch(slice.actions.setPricingsLoading(false));
    }
  };

export default slice;
